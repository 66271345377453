import { Page } from "@/components/Page";
import Head from "next/head";

import { ErrorPage } from "@fllite/ui/components";
import { Layout } from "@fllite/ui/components";

const Page404 = () => (
  <Layout>
    <Head>
      <title>Fllite - 404 Page not found</title>
    </Head>

    <Page>
      <ErrorPage statusCode={404} />
    </Page>
  </Layout>
);

export default Page404;
